import React, { Component } from "react";
import { FormattedMessage as Message } from "react-intl";

import ReactHelmet from "../components/helmet/reactHelmet";
import Beadcrumb from "../components/helmet/beadcrumb";

import translations from "../i18n/locales";

import "./scss/notFound.scss";

export default class NotFound extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locale: props.locale,
    };
  }

  render() {
    return (
      <div className="notFound">
        <ReactHelmet
          title={translations[this.state.locale]["notFound.title"]}
          description={translations[this.state.locale]["notFound.description"]}
          keywords={["not found", "404", "non trouvé"]}
        />
        <Beadcrumb
          path={window.location.pathname}
          origin={window.location.origin}
        />
        <div className="notFound__container">
          <svg
            viewBox="0 0 512.5 372.642"
            xmlns="http://www.w3.org/2000/svg"
            class="notFound__illustration"
          >
            <g>
              <g stroke-miterlimit="10">
                <path
                  d="M329.931 296.465c-23.368-5.409-47.545-7.706-70.127-15.782-22.582-8.075-44.226-23.35-52.278-45.94-13.953-39.141 16.925-79.38 21.354-120.697 4.75-44.297-22.483-87.011-58.655-113.213C137.358-.35 101.64.52 77.483 22.419c-34.731 31.486-26.428 88.377-6.98 131.032 40.458 88.738 117.94 158.027 209.072 191.716 56.704 20.963 120.007 41.42 168.656 13.077-33.541-29.937-74.485-51.634-118.3-61.779z"
                  fill="#4c566a"
                  stroke="#4c566a"
                ></path>
                <path
                  d="M400.633 288.381c-35.311-12.652-74.582-29.013-88.087-64.008-11.279-29.227-.57-61.797-.067-93.121.85-52.954-32.657-103.281-79.05-127.856L174.671.999c-1.473-.06-2.96-.113-4.446-.166 36.172 26.202 63.405 68.916 58.655 113.213-4.43 41.317-35.308 81.557-21.354 120.697 8.053 22.591 29.696 37.865 52.278 45.94 22.582 8.076 46.759 10.373 70.127 15.782 43.815 10.146 84.76 31.842 118.3 61.78 14.322-8.345 27.368-20.935 38.606-38.98a815255.914 815255.914 0 0 1-86.204-30.884z"
                  fill="#434c5e"
                  stroke="#434c5e"
                ></path>
                <path
                  d="M312.479 131.252c-.503 31.324-11.212 63.895.067 93.121 13.505 34.995 52.776 51.355 88.087 64.008 28.735 10.297 57.47 20.591 86.204 30.884 1.946-3.124 3.857-6.36 5.692-9.82 26.036-49.094 22.789-108.579 8.722-162.577l-40.371-11.55c-33.946-9.712-69.51-20.358-94.248-45.552-18.738-19.085-29.274-44.609-45.09-66.179-4.508-6.148-9.805-12.038-15.651-17.234l-72.462-2.956c46.392 24.574 79.899 74.901 79.05 127.855z"
                  fill="#3b4252"
                  stroke="#3b4252"
                ></path>
                <path
                  d="M366.632 89.765c24.738 25.194 60.302 35.84 94.248 45.552l40.371 11.55a413.021 413.021 0 0 0-1.721-6.412c-12.881-46.291-35.769-93.913-78.458-115.969-27.718-14.322-60.036-15.884-91.211-17.156l-23.971-.978c5.847 5.196 11.144 11.086 15.651 17.234 15.816 21.57 26.353 47.094 45.091 66.179z"
                  fill="#3b4252"
                  stroke="#3b4252"
                ></path>
              </g>
              <circle
                cx="397.594"
                cy="180.376"
                fill="#eceff4"
                r="44.6"
              ></circle>
              <path
                d="M429.406 149.12c-4.482 31.898-31.884 56.441-65.019 56.441a65.86 65.86 0 0 1-3.675-.103c8.028 11.781 21.551 19.518 36.882 19.518 24.632 0 44.6-19.969 44.6-44.601 0-12.174-4.88-23.207-12.788-31.255z"
                fill="#e5e9f0"
                opacity=".53"
              ></path>
              <g fill="#e5e9f0" opacity=".56">
                <ellipse
                  cx="415.119"
                  cy="153.604"
                  rx="9.277"
                  ry="8.02"
                  transform="rotate(25.786 415.162 153.629)"
                ></ellipse>
                <circle cx="412.726" cy="198.285" r="8.301"></circle>
                <ellipse
                  cx="430.856"
                  cy="195.008"
                  rx="6.255"
                  ry="5.065"
                  transform="rotate(118.672 430.851 195.01)"
                ></ellipse>
                <circle cx="405.601" cy="167.01" r="2.814"></circle>
                <ellipse
                  cx="376.316"
                  cy="206.586"
                  rx="8.905"
                  ry="10.552"
                  transform="rotate(-60.172 376.322 206.594)"
                ></ellipse>
                <ellipse
                  cx="370.352"
                  cy="155.655"
                  rx="4.98"
                  ry="7.034"
                  transform="rotate(41.343 370.332 155.645)"
                ></ellipse>
                <circle cx="379.402" cy="180.376" r="5.065"></circle>
              </g>
              <g fill="#d8dee9" opacity=".54">
                <path d="M415.579 160.564a8.36 8.36 0 0 1-3.614-.848c-3.581-1.73-5.333-5.543-3.904-8.501.916-1.895 3.013-3.072 5.474-3.072 1.217 0 2.466.293 3.613.848 3.581 1.73 5.334 5.544 3.904 8.501-.916 1.896-3.013 3.072-5.473 3.072zm-3.228 43.208a6.244 6.244 0 0 1-6.237-6.238c0-3.438 2.798-6.236 6.237-6.236s6.237 2.798 6.237 6.236a6.244 6.244 0 0 1-6.237 6.238zm16.62-5.762c-.388 0-.745-.089-1.063-.263-1.428-.78-1.712-3.124-.622-5.117.817-1.495 2.257-2.5 3.581-2.5.387 0 .744.089 1.063.263.85.466 1.131 1.382 1.223 1.886.186 1.017-.033 2.195-.601 3.231-.819 1.495-2.258 2.5-3.581 2.5z"></path>
                <circle cx="405.602" cy="167.01" r=".75"></circle>
                <path d="M378.247 212.838c-1.657 0-3.343-.464-4.874-1.342-4.061-2.328-5.838-6.885-3.961-10.156 1.097-1.913 3.299-3.055 5.892-3.055 1.657 0 3.343.464 4.875 1.342 4.06 2.329 5.837 6.885 3.961 10.157-1.097 1.912-3.3 3.054-5.893 3.054zm-9.118-52.461c-.547 0-.987-.144-1.31-.427-.65-.572-.651-1.565-.594-2.106.125-1.181.739-2.476 1.688-3.553 1.212-1.377 2.807-2.233 4.163-2.233.547 0 .987.144 1.31.427 1.036.911.842 3.459-1.094 5.659-1.212 1.377-2.807 2.233-4.163 2.233zm10.648 22.813a3.006 3.006 0 0 1-3.002-3.002c0-1.654 1.347-3.001 3.002-3.001s3.001 1.347 3.001 3.001a3.004 3.004 0 0 1-3.001 3.002z"></path>
              </g>
              <g fill="#d8dee9">
                <path d="M386.282 292.171l-29.627-32.836 30.662 31.672s2.045 1.949 1.287 2.703c-.603.602-2.322-1.539-2.322-1.539z"></path>
                <path d="M203.516 243.794L182.229 220.2l22.031 22.758s1.469 1.398.925 1.941c-.434.434-1.669-1.105-1.669-1.105z"></path>
                <path d="M289.729 131.935l-17.103-18.956 17.699 18.283s1.181 1.125.744 1.561c-.347.348-1.34-.888-1.34-.888z"></path>
                <path d="M486.315 117.362l-17.102-18.955 17.699 18.283s1.18 1.125.744 1.561c-.348.348-1.341-.889-1.341-.889z"></path>
                <path d="M181.053 50.224l-32.658-36.196 33.798 34.913s2.253 2.148 1.42 2.98c-.666.664-2.56-1.697-2.56-1.697z"></path>
              </g>
              <circle
                cx="96.193"
                cy="212.243"
                fill="#d8dee9"
                r="2.275"
              ></circle>
              <circle
                cx="364.388"
                cy="355.239"
                fill="#d8dee9"
                r="1.516"
              ></circle>
              <circle
                cx="402.42"
                cy="332.706"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="269.504"
                cy="91.391"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="248.718"
                cy="185.696"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="484.074"
                cy="130.322"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle cx="204.88" cy="174.76" fill="#d8dee9" r="2.844"></circle>
              <circle
                cx="308.386"
                cy="60.456"
                fill="#d8dee9"
                r="2.844"
              ></circle>
              <circle
                cx="350.641"
                cy="145.985"
                fill="#d8dee9"
                r="2.844"
              ></circle>
              <circle
                cx="214.976"
                cy="56.691"
                fill="#d8dee9"
                r="2.844"
              ></circle>
              <circle cx="65.073" cy="74.118" fill="#d8dee9" r="2.275"></circle>
              <circle
                cx="414.556"
                cy="60.456"
                fill="#d8dee9"
                r="1.896"
              ></circle>
              <circle
                cx="212.897"
                cy="34.604"
                fill="#d8dee9"
                r="1.896"
              ></circle>
              <circle cx="102.654" cy="16.87" fill="#d8dee9" r="2.844"></circle>
              <circle cx="26.432" cy="71.472" fill="#d8dee9" r="2.844"></circle>
              <circle
                cx="432.642"
                cy="108.36"
                fill="#d8dee9"
                r="2.844"
              ></circle>
              <circle
                cx="305.625"
                cy="344.533"
                fill="#d8dee9"
                r="2.844"
              ></circle>
              <circle
                cx="273.157"
                cy="66.899"
                fill="#d8dee9"
                r="2.844"
              ></circle>
              <circle
                cx="379.066"
                cy="19.716"
                fill="#d8dee9"
                r="2.844"
              ></circle>
              <circle
                cx="328.136"
                cy="242.676"
                fill="#d8dee9"
                r="2.844"
              ></circle>
              <circle
                cx="48.885"
                cy="148.677"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="331.58"
                cy="122.255"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="102.075"
                cy="42.419"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="204.589"
                cy="99.149"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="135.827"
                cy="48.525"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle cx="135.12" cy="22.488" fill="#d8dee9" r="1.138"></circle>
              <circle
                cx="261.031"
                cy="17.296"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="446.072"
                cy="167.946"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="386.487"
                cy="260.422"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle cx="372.492" cy="48.55" fill="#d8dee9" r="1.138"></circle>
              <circle
                cx="452.327"
                cy="339.438"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle cx="438.5" cy="135.775" fill="#d8dee9" r="1.138"></circle>
              <circle cx="384.11" cy="95.614" fill="#d8dee9" r="1.138"></circle>
              <circle cx="48.885" cy="82.726" fill="#d8dee9" r="1.138"></circle>
              <circle
                cx="159.983"
                cy="68.627"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="127.499"
                cy="223.931"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="325.291"
                cy="274.378"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="249.813"
                cy="324.229"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="396.926"
                cy="237.667"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="496.664"
                cy="161.331"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="327.014"
                cy="16.158"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="213.971"
                cy="124.449"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="165.251"
                cy="202.689"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="193.767"
                cy="157.09"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="178.601"
                cy="233.831"
                fill="#d8dee9"
                r=".758"
              ></circle>
              <circle
                cx="124.136"
                cy="199.654"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="134.365"
                cy="183.101"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="281.366"
                cy="180.755"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="355.574"
                cy="232.821"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="364.388"
                cy="135.775"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="124.136"
                cy="252.815"
                fill="#d8dee9"
                r="1.137"
              ></circle>
              <circle
                cx="253.401"
                cy="243.07"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="174.164"
                cy="261.815"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="144.124"
                cy="241.932"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="294.927"
                cy="28.137"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="448.231"
                cy="62.353"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="476.592"
                cy="295.943"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="498.061"
                cy="243.501"
                fill="#d8dee9"
                r="2.844"
              ></circle>
              <circle
                cx="453.755"
                cy="223.312"
                fill="#d8dee9"
                r="1.896"
              ></circle>
              <circle
                cx="436.219"
                cy="273.73"
                fill="#d8dee9"
                r="2.844"
              ></circle>
              <circle
                cx="468.927"
                cy="247.484"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="274.054"
                cy="155.573"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="69.205"
                cy="236.107"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="251.563"
                cy="148.397"
                fill="#d8dee9"
                r="2.844"
              ></circle>
              <circle
                cx="239.437"
                cy="98.794"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <circle
                cx="273.333"
                cy="109.635"
                fill="#d8dee9"
                r="1.517"
              ></circle>
              <circle
                cx="281.896"
                cy="226.776"
                fill="#d8dee9"
                r="2.844"
              ></circle>
              <circle
                cx="226.121"
                cy="212.838"
                fill="#d8dee9"
                r="1.138"
              ></circle>
              <path
                d="M496.331 217.321c0 3.406-2.819 5.99-6.344 5.99-3.642 0-6.344-2.584-6.344-5.99v-11.865h-27.604c-3.877 0-6.579-3.054-6.579-6.462 0-.468.353-2.349 1.058-3.642l22.554-54.27c.94-2.232 3.289-3.643 5.639-3.643 3.055 0 6.344 2.82 6.344 6.109 0 .587 0 1.292-.353 2.114-3.406 7.87-16.681 40.175-20.088 48.045h19.03V173.27c0-3.407 2.702-5.991 6.344-5.991 3.407 0 6.344 2.584 6.344 5.991v20.439h3.289c3.406 0 6.107 2.702 6.107 5.873 0 3.289-2.701 5.874-6.107 5.874h-3.289v11.865z"
                fill="#eceff4"
              ></path>
              <g fill="#e5e9f0">
                <circle cx="455.651" cy="197.878" r="2.68"></circle>
                <circle cx="490.569" cy="189.552" r="3.486"></circle>
                <path d="M478.483 153.11a4.44 4.44 0 0 0-3.913 4.407c0 2.077 1.433 3.804 3.358 4.288 1.164-2.793 2.228-5.339 3.123-7.469a4.43 4.43 0 0 0-3.094-1.26c.179.001.355.015.526.034z"></path>
                <circle cx="473.735" cy="202.864" r="1.472"></circle>
                <circle cx="494.055" cy="215.1" r="1.472"></circle>
                <circle cx="466.636" cy="167.157" r="1.471"></circle>
              </g>
              <path
                d="M453.608 197.878a2.678 2.678 0 0 1 2.361-2.659 2.68 2.68 0 1 0-.319 5.34c.108 0 .214-.009.319-.021a2.68 2.68 0 0 1-2.361-2.66zm34.304-8.326a3.484 3.484 0 0 1 3.071-3.459 3.486 3.486 0 1 0-.415 6.945c.141 0 .278-.012.415-.027a3.484 3.484 0 0 1-3.071-3.459zm-14.198-33.355zm-.176 1.775zm4.329 3.982l.062-.148c-1.926-.484-3.358-2.211-3.358-4.288a4.44 4.44 0 0 1 3.913-4.407 4.553 4.553 0 0 0-.525-.033h-.002a4.414 4.414 0 0 0-3.14 1.301 4.425 4.425 0 0 0-1.278 3.593 4.436 4.436 0 0 0 4.328 3.982zm-5.254 40.91c0-.753.566-1.373 1.297-1.46a1.472 1.472 0 1 0 0 2.92 1.47 1.47 0 0 1-1.297-1.46zm20.32 12.236a1.47 1.47 0 0 1 1.297-1.459 1.472 1.472 0 1 0 0 2.919 1.47 1.47 0 0 1-1.297-1.46zm-27.418-47.943c0-.753.566-1.373 1.296-1.46a1.471 1.471 0 1 0 0 2.92 1.471 1.471 0 0 1-1.296-1.46z"
                fill="#d8dee9"
              ></path>
              <path
                d="M336.331 217.321c0 3.406-2.819 5.99-6.344 5.99-3.642 0-6.344-2.584-6.344-5.99v-11.865h-27.604c-3.877 0-6.579-3.054-6.579-6.462 0-.468.353-2.349 1.058-3.642l22.554-54.27c.94-2.232 3.289-3.643 5.639-3.643 3.055 0 6.344 2.82 6.344 6.109 0 .587 0 1.292-.353 2.114-3.406 7.87-16.681 40.175-20.088 48.045h19.03V173.27c0-3.407 2.702-5.991 6.344-5.991 3.407 0 6.344 2.584 6.344 5.991v20.439h3.289c3.406 0 6.107 2.702 6.107 5.873 0 3.289-2.701 5.874-6.107 5.874h-3.289v11.865z"
                fill="#eceff4"
              ></path>
              <g fill="#e5e9f0">
                <circle cx="295.651" cy="197.878" r="2.68"></circle>
                <circle cx="330.569" cy="189.552" r="3.486"></circle>
                <path d="M318.483 153.11a4.44 4.44 0 0 0-3.913 4.407c0 2.077 1.433 3.804 3.358 4.288 1.164-2.793 2.228-5.339 3.123-7.469a4.43 4.43 0 0 0-3.094-1.26c.179.001.355.015.526.034z"></path>
                <circle cx="313.735" cy="202.864" r="1.472"></circle>
                <circle cx="334.055" cy="215.1" r="1.472"></circle>
                <circle cx="306.636" cy="167.157" r="1.471"></circle>
              </g>
              <path
                d="M293.608 197.878a2.678 2.678 0 0 1 2.361-2.659 2.68 2.68 0 1 0-.319 5.34c.108 0 .214-.009.319-.021a2.68 2.68 0 0 1-2.361-2.66zm34.304-8.326a3.484 3.484 0 0 1 3.071-3.459 3.486 3.486 0 1 0-.415 6.945c.141 0 .278-.012.415-.027a3.484 3.484 0 0 1-3.071-3.459zm-14.198-33.355zm-.176 1.775zm4.329 3.982l.062-.148c-1.926-.484-3.358-2.211-3.358-4.288a4.44 4.44 0 0 1 3.913-4.407 4.553 4.553 0 0 0-.525-.033h-.002a4.414 4.414 0 0 0-3.14 1.301 4.425 4.425 0 0 0-1.278 3.593 4.436 4.436 0 0 0 4.328 3.982zm-5.254 40.91c0-.753.566-1.373 1.297-1.46a1.472 1.472 0 1 0 0 2.92 1.47 1.47 0 0 1-1.297-1.46zm20.32 12.236a1.47 1.47 0 0 1 1.297-1.459 1.472 1.472 0 1 0 0 2.919 1.47 1.47 0 0 1-1.297-1.46zm-27.418-47.943c0-.753.566-1.373 1.296-1.46a1.471 1.471 0 1 0 0 2.92 1.471 1.471 0 0 1-1.296-1.46z"
                fill="#d8dee9"
              ></path>
            </g>
            <g class="notFound__illustration__cloudTop">
              <path
                d="M196.938 136.375v-.113a6.429 6.429 0 0 0-6.429-6.428h-21.63a6.429 6.429 0 0 1-6.429-6.43v-1.254a6.429 6.429 0 0 1 6.429-6.429h2.174a6.43 6.43 0 0 0 6.429-6.43v-.112a6.43 6.43 0 0 0-6.429-6.429h-11.07a6.429 6.429 0 0 1-6.429-6.43v-.311a6.429 6.429 0 0 1 6.429-6.429h20.462a6.429 6.429 0 0 0 6.429-6.429v-.113a6.43 6.43 0 0 0-6.429-6.429H95.803a6.429 6.429 0 0 0-6.429 6.429v.113a6.429 6.429 0 0 0 6.429 6.429h2.349a6.429 6.429 0 0 1 6.429 6.429v.311a6.43 6.43 0 0 1-6.429 6.43H35.338a6.43 6.43 0 0 0-6.429 6.429v.112a6.43 6.43 0 0 0 6.429 6.43h42.277a6.429 6.429 0 0 1 6.429 6.429v1.254a6.43 6.43 0 0 1-6.429 6.43h-5.964a6.429 6.429 0 0 0-6.429 6.428v.113a6.43 6.43 0 0 0 6.429 6.43h21.133a6.429 6.429 0 0 1 6.429 6.428v.822a6.43 6.43 0 0 1-6.429 6.429H6.429A6.43 6.43 0 0 0 0 162.913v.112a6.429 6.429 0 0 0 6.429 6.429h25.433a6.429 6.429 0 1 1 0 12.858H19.768a6.428 6.428 0 0 0-6.429 6.428v.113a6.43 6.43 0 0 0 6.429 6.43h84.642a6.43 6.43 0 0 0 6.429-6.43v-.113a6.428 6.428 0 0 0-6.429-6.428h-8.836a6.43 6.43 0 0 1 0-12.858h75.479a6.43 6.43 0 0 0 6.429-6.429v-.112a6.43 6.43 0 0 0-6.429-6.429h-11.07a6.429 6.429 0 0 1-6.429-6.429v-.822a6.428 6.428 0 0 1 6.429-6.428h30.525a6.43 6.43 0 0 0 6.43-6.43z"
                fill="#eceff4"
              ></path>
              <path
                d="M177.445 83.038v.113a6.429 6.429 0 0 1-6.429 6.429h9.43a6.429 6.429 0 0 0 6.429-6.429v-.113a6.43 6.43 0 0 0-6.429-6.429h-9.43a6.43 6.43 0 0 1 6.429 6.429zm-17.462 6.542h-9.43a6.43 6.43 0 0 0-6.429 6.429v.311a6.43 6.43 0 0 0 6.429 6.43h9.43a6.429 6.429 0 0 1-6.429-6.43v-.311a6.43 6.43 0 0 1 6.429-6.429zm0 53.224h-9.43a6.429 6.429 0 0 0-6.429 6.428v.822a6.429 6.429 0 0 0 6.429 6.429h9.43a6.429 6.429 0 0 1-6.429-6.429v-.822a6.429 6.429 0 0 1 6.429-6.428zm2.467-19.4v-1.254a6.429 6.429 0 0 1 6.429-6.429h2.174a6.43 6.43 0 0 0 6.429-6.43v-.112a6.43 6.43 0 0 0-6.429-6.429h-9.43a6.429 6.429 0 0 1 6.429 6.429v.112a6.43 6.43 0 0 1-6.429 6.43h-2.174a6.429 6.429 0 0 0-6.429 6.429v1.254a6.43 6.43 0 0 0 6.429 6.43h9.43a6.43 6.43 0 0 1-6.429-6.43zm28.059 6.429h-9.43a6.429 6.429 0 0 1 6.429 6.428v.113a6.43 6.43 0 0 1-6.429 6.43h9.43a6.43 6.43 0 0 0 6.429-6.43v-.113a6.429 6.429 0 0 0-6.429-6.428zm-86.099 52.48h-8.836a6.43 6.43 0 0 1 0-12.858h-9.429a6.429 6.429 0 1 0 0 12.858h8.836a6.429 6.429 0 0 1 6.429 6.428v.113a6.43 6.43 0 0 1-6.429 6.43h9.43a6.43 6.43 0 0 0 6.429-6.43v-.113a6.43 6.43 0 0 0-6.43-6.428zm66.643-25.829h-9.43a6.429 6.429 0 0 1 6.429 6.429v.112a6.429 6.429 0 0 1-6.429 6.429h9.43a6.43 6.43 0 0 0 6.429-6.429v-.112a6.429 6.429 0 0 0-6.429-6.429z"
                fill="#d8dee9"
              ></path>
            </g>
            <g class="notFound__illustration__cloudBottom">
              <path
                d="M140.948 279.726v-.306a6.333 6.333 0 0 1 6.333-6.333h94.625a6.333 6.333 0 0 0 0-12.665h-1.249a6.332 6.332 0 0 1-6.332-6.332v-.306a6.333 6.333 0 0 1 6.332-6.333h72.089a6.333 6.333 0 0 1 6.333 6.333v.306a6.332 6.332 0 0 1-6.333 6.332h-24.622a6.332 6.332 0 1 0 0 12.665h1.345a6.332 6.332 0 0 1 6.332 6.333v.306a6.332 6.332 0 0 1-6.332 6.332h-26.386a6.332 6.332 0 1 0 0 12.665h61.068a6.332 6.332 0 0 1 6.332 6.333v.306a6.332 6.332 0 0 1-6.332 6.332H173.775a6.333 6.333 0 0 1-6.333-6.332v-.306a6.333 6.333 0 0 1 6.333-6.333h4.016a6.332 6.332 0 1 0 0-12.665H147.28a6.332 6.332 0 0 1-6.332-6.332z"
                fill="#eceff4"
              ></path>
              <path
                d="M271.616 298.723h-10.674a6.332 6.332 0 0 1 0-12.665h10.674a6.332 6.332 0 1 0 0 12.665zm26.385-12.665h-10.674a6.332 6.332 0 0 0 6.333-6.332v-.306a6.333 6.333 0 0 0-6.333-6.333h-1.343a6.332 6.332 0 1 1 0-12.665h10.673a6.333 6.333 0 0 0 0 12.665h1.344a6.332 6.332 0 0 1 6.332 6.333v.306a6.332 6.332 0 0 1-6.332 6.332zm23.277-25.636h-10.673a6.333 6.333 0 0 0 6.333-6.332v-.306a6.334 6.334 0 0 0-6.333-6.333h10.673a6.333 6.333 0 0 1 6.333 6.333v.306a6.332 6.332 0 0 1-6.333 6.332zm7.065 44.939v-.306a6.332 6.332 0 0 0-6.332-6.333h10.674a6.332 6.332 0 0 1 6.331 6.333v.306a6.332 6.332 0 0 1-6.331 6.332h-10.674a6.332 6.332 0 0 0 6.332-6.332z"
                fill="#d8dee9"
              ></path>
            </g>
          </svg>
          <div className="notFound__description">
            <h3>
              <Message
                id="notFound.story"
                defaultMessage="One day, somewhere in the space..."
              />
            </h3>
            <p>
              <Message
                id="notFound.message"
                defaultMessage="...Oh no, that's not the page you were looking for."
              />
            </p>
          </div>
        </div>
      </div>
    );
  }
}
